@import "../../shared/constants.scss";

.worker-planner-printable-page {
  display: block;
  box-sizing: border-box;
  min-width: 1500px;
  max-width: 1500px;
  width: 1500px;
  padding: 5px;

  .fc .fc-event,
  .fc .fc-scrollgrid table tr {
    -moz-column-break-inside: avoid;
    break-inside: avoid;
  }

  .printable-planner-wrapper {
    display: block;

    .calendar-inner .fc-datagrid-cell-cushion {
      width: 100%;
    }
  }
  .stangeland-logo-printable {
    height: 32px;
    transform: scaleY(1.3);
    padding: 0;
    margin: 0;
  }
}
