.header{
    font-size: 14px;
}

.content{
    font-size: 12px;
}

.bold{
    font-weight: bold;
}


.mb-s {
    margin-bottom: 5px;
}

.mb-m {
    margin-bottom: 10px;
}

.line-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .left {
        display: inline;
    
    }
    .right {
        display: inline;
    }
}