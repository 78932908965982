.align-icon {
    display: flex;
    align-items: center;
    gap: 5px;
}

.assigned-color {
    color: orange;
}
.created-color {
    color: orange;
}
.approved-color {
    color: blue;
}
.not-approved-color {
    color: red;
}
.cancelled-color {
    color: gray;
}
