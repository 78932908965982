@import "../../shared/constants.scss";

.worker-details {

    div {
        margin-top: 12px;
        font-size: 16px;
    }
}

.worker-certification {
    
}

.margin-left-230 {
    margin-left: 230px
}